import moment from "moment";
import { useState } from "react";
import { constants } from "../../../../../../../constants";
import { usePreviousTransaction } from "../../../../../../../effects/amr/usePreviousTransaction";
import { TransactionType } from "../../../../../../../types/amr-pipeline/enums/TransactionType";
import { OriginatingTransaction } from "../../../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { OriginatingTransactionClass } from "../../../../../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { SubscriptionFeature } from "../../../../../../../types/billing/SubscriptionFeature";
import { DealSecurity, DealSecurityStatistics } from "../../../../../../../types/security/DealSecurityStatistics";
import { user } from "../../../../../../../user";
import { isRequestSuccess } from "../../../../../../../utils";
import { SubscribeLink } from "../../../../../../access/BlockedFeatureText";
import { RequiredFeature } from "../../../../../../access/RequiredFeature";
import { Table } from "../../../../../../bidding/common/table";
import { ColumnBuilder } from "../../../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../../../../../bidding/common/table/types/ColumnDefinition";
import { useDealSecurityStatistics } from "../../../../../../clo-managers/tabs/deals/hooks";
import { EmptyPlaceholder, Preloader, TickerButton } from "../../../../../../common";
import { currentBwicColumn, inventoryColumn, lastTradedColumn, lastTradedColorColumn, lastTradedMineColumn, myBwicColumn } from "../../../../../aggregated/arranger-pipeline/arranger-pipeline.columns";
import { SectionContent } from "../../../../../common/section/SectionContent";
import { PreviousTransactionControlPanel } from "./PreviousTransactionControlPanel";
import { convertToDealSecurityTableItem, DealSecurityTableItem } from "../../../../../../clo-managers/tabs/deals/columns";
import { FormField } from "../../../../../common/FormField";

interface Props {
    transaction: OriginatingTransaction
}

export function PreviousTransaction({ transaction }: Props) {
    const hasAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);
    const { previousTransaction } = usePreviousTransaction(transaction.dealReferenceName, transaction.referenceName, !hasAccess);

    if ((transaction.type !== TransactionType.refi && transaction.type !== TransactionType.reset) ||
        !transaction.dealTicker ||
        (previousTransaction == null && hasAccess)
    ) {
        return null;
    }

    return (
        <SectionContent
            title="Capital Structure — Previous Transaction"
            className="data-item-previous-transaction"
        >
            <RequiredFeature
                inline
                className="empty-placeholder"
                feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                text={<><SubscribeLink text="Subscribe to the Pro plan" /> to see the Capital Structure — Previous Transaction</>}
            >
                {
                    user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess) &&
                    previousTransaction != null &&
                        <>
                            {previousTransaction.closingDate != null && <ClosingDate date={previousTransaction.closingDate} />}
                            <PreviousTransactionTable transaction={transaction} previousTransaction={previousTransaction} />
                        </>
                    
                }
            </RequiredFeature>
        </SectionContent>

    );
}

interface ClosingDateProps {
    date: Date
}
function ClosingDate({ date }: ClosingDateProps) {
    return (
        <FormField title="Closing Date">
            {moment(date).format(constants.dateFormatDoubleDay)}
        </FormField>
    )
}

interface PreviousTransactionTableDataItem extends OriginatingTransactionClass {
    security?: DealSecurityTableItem
}

const convertToTableDataItem = (
    transaction: OriginatingTransaction,
    _class: OriginatingTransactionClass,
    statistics?: DealSecurityStatistics) => {
    const security = statistics?.classes.find(c => {
        const tickers = [
            _class.tranche?.ticker144A,
            _class.tranche?.tickerAccdInvCertif,
            _class.tranche?.tickerRegS
        ];

        return tickers.some(t => c.ticker.localeCompare(t ?? '', undefined, { sensitivity: 'accent' }) === 0)
    });

    return {
        ..._class,
        security: security ? convertToDealSecurityTableItem(security, transaction) : undefined
    };
}

interface PreviousTransactionTableProps extends Props {
    previousTransaction: OriginatingTransaction
}
function PreviousTransactionTable({ transaction, previousTransaction }: PreviousTransactionTableProps) {
    const { requestState, statistics } = useDealSecurityStatistics([transaction.dealTicker!]);

    const [selectedSecurity, setSelectedSecurity] = useState<DealSecurity | null>(null);

    const columns: IColumnDefinition<PreviousTransactionTableDataItem>[] = [{
        columnKey: "className",
        renderColumnHeaderContent: () => "Class",
        renderColumnContent: _class => _class.name,
    }, {
        columnKey: "ticker",
        renderColumnHeaderContent: () => "Ticker",
        renderColumnContent: _class => _class.security
            ? <TickerButton
                security={_class.security}
                fetchAgencyRatings={true}
                onClick={(e: MouseEvent, security: DealSecurity | null) => setSelectedSecurity(security)}
            /> : (_class.tranche?.ticker144A || constants.emptyPlaceholder),
        className: "data-list-cell-lg",
    },
        myBwicColumn,
        currentBwicColumn,
        inventoryColumn,
        lastTradedMineColumn,
        lastTradedColumn,
        lastTradedColorColumn
    ];

    return (
        <Preloader inProgress={!isRequestSuccess(requestState)}>
            {previousTransaction?.classes.length ?
                <div className="previous-transaction-table">
                    <Table
                        columns={columns.map(c => new ColumnBuilder(c))}
                        dataItems={previousTransaction.classes.map(c => convertToTableDataItem(transaction, c, statistics[0]))}
                        createSecurityCustomClassName={(c: PreviousTransactionTableDataItem) => ({
                            highlighted: c.security && selectedSecurity && c.security.ticker === selectedSecurity?.ticker
                        })}
                        className="data-list-striped"
                    />
                    <PreviousTransactionControlPanel security={selectedSecurity} onClose={() => setSelectedSecurity(null)} />
                </div>
                : <EmptyPlaceholder />
            }
        </Preloader>
    );
}