import { General } from "./sections/General";
import { Dates } from "./sections/Dates";
import { AdditionalInfo } from "./sections/AdditionalInfo";
import { SyndicateContacts } from './sections/SyndicateContacts';
import { Intex } from './sections/Intex';
import { TabBody } from '../../../common/tabs/TabBody';
import { withTabTracking } from '../withTabTracking';
import { TransactionTabProps } from '../types';
import { TransactionAccessType } from '../../../../../types/amr-pipeline/enums/TransactionAccessType';
import { ShowFor } from "../../../../access";
import { roles } from "../../../../../constants";
import { PreviousTransaction } from "./sections/previous-transaction/PreviousTransaction";
import { useEffect, useRef } from "react";

const DealOverviewTab = ({ transaction, difference }: TransactionTabProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (wrapperRef.current) {
            wrapperRef?.current.scrollIntoView();
        }
    }, [transaction.referenceName, difference?.version?.currentValue]);

    return (
        <TabBody className="component-tabs-deal-details">
            <div className="tabs-data-padding" ref={wrapperRef}>
                <General selectedTransaction={transaction} difference={difference} />
                <Dates selectedTransaction={transaction} difference={difference} />
                <AdditionalInfo selectedTransaction={transaction} difference={difference} />
                <SyndicateContacts contacts={transaction.syndicateContacts} />
                <ShowFor roles={roles.getAllRolesExclude(roles.Media, roles.Viewer)}>
                    <Intex selectedTransaction={transaction} difference={difference} />
                </ShowFor>
                {!difference && <PreviousTransaction transaction={transaction} />}
            </div>
        </TabBody>
    );
}

export const DealOverview = withTabTracking(TransactionAccessType.Overview)(DealOverviewTab);
